<template>
  <div class="g_page_box">
    <g-loading :loading="loading" />
    <div class="g_main_content">
      <iframe :src="url" width="100%" height="100%" ref="iframe" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      url: this.$route.query.url,
    }
  },
  computed: {
  },
  components: {},
  watch: {},
  created() {
    document.title = this.$route.query.title
  },
  mounted () {
    this.$refs.iframe.onload = () => {
      this.loading = false
    }
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
